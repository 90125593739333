<template>
  <div>
    <div class="nav">
      <div
        v-for="(item, index) in nav"
        :key="index"
        class="navItem"
        @click="navClick(index)"
      >
        <div class="navItem-name">{{ item.name }}</div>
        <div class="nav-underline" v-if="index == navIndex"></div>
      </div>
    </div>
    <div class="content" v-if="navIndex == 0">
      <div class="customer">
        <div class="customer-list">
          <div>客户统计</div>
          <div class="customer-list_right">
            <div
              :class="type == 1 ? 'customer-color' : ''"
              @click="dayClick(1)"
            >
              近7日
            </div>
            <p :class="type == 2 ? 'customer-color' : ''" @click="dayClick(2)">
              近30天
            </p>
          </div>
        </div>
        <div class="customer-three">
          <div class="customer-threeItem">
            <div>总人数</div>
            <div class="customer-num">{{ numObj.contact_num || 0 }}</div>
          </div>
          <div class="customer-threeItem" style="position:relative;">
            <div class="three-line left-three"></div>
            <div>新增</div>
            <div class="customer-num">{{ numObj.add_num || 0 }}</div>
            <div class="three-line right-three"></div>
          </div>
          <div class="customer-threeItem">
            <div>流失</div>
            <div class="customer-num">{{ numObj.lost_num || 0 }}</div>
          </div>
        </div>
        <apexchart
          width="350"
          height="280"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
      <div class="source">
        <div class="source-title">客户来源</div>
        <apexchart
          class="apex-charts"
          height="500"
          type="donut"
          dir="ltr"
          :options="Options"
          :series="pieSeries"
        ></apexchart>
      </div>
    </div>

    <div class="content" v-if="navIndex == 1">
      <div class="customer">
        <div class="customer-list">
          <div>客户统群统计</div>
          <div class="customer-list_right">
            <div
              :class="type == 1 ? 'customer-color' : ''"
              @click="dayClick(1)"
            >
              近7日
            </div>
            <p :class="type == 2 ? 'customer-color' : ''" @click="dayClick(2)">
              近30天
            </p>
          </div>
        </div>
        <div class="customer-three">
          <div class="customer-threeItem">
            <div>客户群总数</div>
            <div class="customer-num">{{ gruopObj.group_num || 0 }}</div>
          </div>
          <div class="customer-threeItem" style="position:relative;">
            <div class="three-line left-three"></div>
            <div>群总人数</div>
            <div class="customer-num">{{ gruopObj.contact_num || 0 }}</div>
            <div class="three-line right-three"></div>
          </div>
          <div class="customer-threeItem" style="position:relative;">
            <div class="three-line left-three"></div>
            <div>新入群</div>
            <div class="customer-num">{{ gruopObj.add_num || 0 }}</div>
            <div class="three-line right-three"></div>
          </div>
          <div class="customer-threeItem">
            <div>退群数</div>
            <div class="customer-num">{{ gruopObj.lost_num || 0 }}</div>
          </div>
        </div>
        <apexchart
          width="350"
          height="280"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
      <div class="table">
        <div class="table-title">我的客户群列表</div>
        <table class="tableBorder">
          <tr v-for="(item, index) in group_list" :key="index">
            <td
              class="borderColor"
              :style="index == 0 ? 'border-top: transparent;' : ''"
            >
              {{ item.name }}
            </td>
            <td :style="index == 0 ? 'border-top: transparent;' : ''">
              {{ item.member_count }} 人
            </td>
            <td :style="index == 0 ? 'border-top: transparent;' : ''">
              {{ item.active_count }}人
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import * as request from "../api/data";
import _ from "lodash";
import { Toast } from "vant";

export default {
  data() {
    return {
      group_list: [],
      nav: [{ name: "客户统计" }, { name: "客户群统计" }],
      navIndex: 0,
      chartOptions: {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: 2,
        },
        xaxis: {
          // categories: [],
        },
        dataLabels: {
          enabled: false,
        },
      },
      series: [
        {
          name: "新增",
          color: "#E1544D",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "流失",
          color: "#58B878",
          data: [0, 0, 0, 0, 0, 0, 0],
        },
      ],
      Options: {
        labels: [],
        colors: [
          "#E1544D",
          "#FF714F",
          "#FACC99",
          "#FFCC63",
          "#4C84FF",
          "#23AD7B",
        ],
        dataLabels: {
          enabled: false, //不显示百分比
        },
        plotOptions: {
          pie: {
            donut: {
              size: "65%", // 显示圆圈的大小尺寸
            },
          },
        },
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: false,
          fontSize: "16px",
          offsetX: 0,
          offsetY: -10,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 330,
              },
              legend: {
                show: true,
              },
            },
          },
        ],
      },
      pieSeries: [],
      type: 1, // 1==7天  2==30天
      numObj: "",
      gruopObj: "",
    };
  },
  mounted() {
    if (this.$route.query.type == 0) {
      this.navIndex = 0;
      this.getList();
    } else if (this.$route.query.type == 1) {
      this.navIndex = 1;
      this.getMoreList();
    } else {
      this.getList();
    }
  },
  methods: {
    navClick(index) {
      this.navIndex = index;
      if (index == 0) {
        this.getList();
      } else {
        this.getMoreList();
      }
    },
    async getList() {
      let res = await request._getStatistics({
        userid: localStorage.getItem("userid"),
        type: this.type,
      });
      console.log("首页 res", res);
      if (_.toInteger(res.code) === 1) {
        this.numObj = {
          contact_num: res.data.data.contact_num,
          add_num: res.data.data.add_num,
          lost_num: res.data.data.lost_num,
        };
        this.series = [
          {
            name: "新增",
            color: "#E1544D",
            data: res.data.data.add_arr,
          },
          {
            name: "流失",
            color: "#58B878",
            data: res.data.data.lost_arr,
          },
        ];
        // this.Options.labels = res.data.data.add_way_arr;
        this.Options = {
          labels: res.data.data.add_way_arr,
          colors: [
            "#E1544D",
            "#FF714F",
            "#FACC99",
            "#FFCC63",
            "#4C84FF",
            "#23AD7B",
          ],
          dataLabels: {
            enabled: false, //不显示百分比
          },
          plotOptions: {
            pie: {
              donut: {
                size: "65%", // 显示圆圈的大小尺寸
              },
            },
          },
          legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            verticalAlign: "middle",
            floating: false,
            fontSize: "16px",
            offsetX: 0,
            offsetY: -10,
          },
          responsive: [
            {
              breakpoint: 600,
              options: {
                chart: {
                  height: 330,
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        };
        this.pieSeries = res.data.data.add_way_num;
      }
    },
    async getMoreList() {
      let res = await request._getGroupStatistics({
        userid: localStorage.getItem("userid"),
        type: this.type,
      });
      console.log("首页 res", res);
      if (_.toInteger(res.code) === 1) {
        this.gruopObj = {
          group_num: res.data.data.group_num,
          contact_num: res.data.data.contact_num,
          add_num: res.data.data.add_num,
          lost_num: res.data.data.lost_num,
        };
        this.series = [
          {
            name: "新增",
            color: "#E1544D",
            data: res.data.data.add_arr,
          },
          {
            name: "流失",
            color: "#58B878",
            data: res.data.data.lost_arr,
          },
        ];
        // this.Options.labels = res.data.data.add_way_arr;
        this.Options = {
          labels: res.data.data.add_way_arr,
          colors: [
            "#E1544D",
            "#FF714F",
            "#FACC99",
            "#FFCC63",
            "#4C84FF",
            "#23AD7B",
          ],
          dataLabels: {
            enabled: false, //不显示百分比
          },
          plotOptions: {
            pie: {
              donut: {
                size: "65%", // 显示圆圈的大小尺寸
              },
            },
          },
          legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            verticalAlign: "middle",
            floating: false,
            fontSize: "16px",
            offsetX: 0,
            offsetY: -10,
          },
          responsive: [
            {
              breakpoint: 600,
              options: {
                chart: {
                  height: 330,
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        };
        this.pieSeries = res.data.data.add_way_num;
        this.group_list = res.data.data.group_list;
      }
    },

    dayClick(type) {
      this.type = type;
      if (this.navIndex == 0) {
        this.getList();
      } else {
        this.getMoreList();
      }
    },
  },
};
</script>

<style scoped>
.nav {
  width: 100%;
  padding: 0 111px 0 127px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #7d8393;
  background: #fff;
}

.navItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100px;
}

.navItem-name {
  margin-top: 34px;
}
.nav-underline {
  width: 91px;
  height: 6px;
  background: #0558f4;
  border-radius: 3px 3px 3px 3px;
  bottom: 0;
  position: absolute;
}

.content {
  background: #f6f7fb;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 0;
}

.customer {
  width: 690px;
  height: 887px;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.customer-list {
  width: 630px;
  display: flex;
  justify-content: space-between;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
  padding: 30px 0;
  border-bottom: 1px solid #d6e5ee;
}

.customer-list_right {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #7d8393;
}

.customer-list_right p {
  margin-left: 29px;
}
.customer-three {
  width: 690px;
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
  margin: 37px 0 7px 0;
}

.customer-num {
  display: flex;
  align-items: center;
  font-size: 60px;
  font-family: Akrobat-Bold, Akrobat;
  font-weight: bold;
  color: #0558f4;
  justify-content: space-between;
  padding: 7px 0 47px 0;
}

.customer-threeItem {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 33.3%;
}

.three-line {
  width: 1px;
  height: 59px;
  background: #d6e5ee;
  position: absolute;
}

.left-three {
  left: 0;
  bottom: 53px;
}

.right-three {
  right: 0;
  bottom: 53px;
}

.source {
  width: 690px;
  height: 717px;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.source-title {
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
  width: 630px;
  padding: 30px 0;
  border-bottom: 1px solid #d6e5ee;
}

.table {
  width: 690px;
  padding: 30px 0 36px 0;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
  margin: 16px auto;
}

.table-title {
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
  margin: 0 0 28px 30px;
}

.tableBorder {
  width: 630px;
  margin: 0 auto;
  border-radius: 16px 16px 16px 16px;
  border-collapse: separate;
  border: 1px solid #d6e5ee;
  border-spacing: 0;
}

.table td {
  height: 83px;
  text-align: center;
}

td,
th {
  border-right: 1px solid #d6e5ee;
  text-align: center;
}

td {
  border-top: 1px solid #d6e5ee;
}

td:last-child,
th:last-child {
  border-right-color: transparent;
}

.borderColor {
  background: #f6f7fb;
}

.customer-color {
  color: #0558f4;
}
</style>
